import axios from 'axios';
import { Notify } from 'vant';
import router from '../router';
// const queryHost = 'http://10.30.11.13:8092'
// const queryHost = window.location.href.indexOf('tsguess-api-test.woa.com') > -1
//   ? 'https://tsguess-api-test.woa.com'
//   : 'https://tsguess-api.woa.com';
const instance = axios.create({
  // baseURL: "http://devbase.qq.com:7001", // 正式服
  // baseURL: "http://10.28.17.80:8188", // 测试服
  // baseURL: `${queryHost}`, // 开发
  timeout: 6000,
  withCredentials: true,
});
// console.log(process.env);
// axios.defaults.baseURL='http://10.28.17.149/api/h5'
// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在请求发送到后端之前，给请求头添加 token
    config.headers.Authorization = `Bearer ${localStorage.auth_token}`;
    return config;
  },
  err => Promise.reject(err),
);

// 处理数据   响应拦截器
instance.interceptors.response.use(
  config =>
    // 响应成功
    {
      console.log(config)
      return {
        ...config.data,
        $$headers: config.headers,
        $$status: config.status,
      };
    }
  ,
  (err) => {
    if (err && err.response && err.response.status) {
      switch (err.response.status) {
        case 401:
          if (err.response.data.errorCode == 1003) {
            Notify(err.response.data.errorMessage, '身份认证');
          }
          break;
        case 404:
          err.message = '资源找不到';
          break;
        case 500:
          if (err.response.data.errorCode == 1012) {
            err.message = 'Token无效';
            Notify('未登录，请先登录', '身份认证');
            router.push({
              path: '/choujiang/login',
              query: { url: router.history._startLocation },
            });
            // router.push(`/choujiang/login`)
          } else if (err.response.data.errorCode == 1011) {
            err.message = 'Token过期';
            Notify('未登录，请先登录', '身份认证');
            router.push({
              path: '/choujiang/login',
              query: { url: router.history._startLocation },
            });
          }
          err.message = '服务器错误';
          break;
        default:
          err.message = '服务器错误';
          break;
      }
    } else {
      err.message = '服务器错误';
    }
    return Promise.reject(err.message);
  },
);

export default instance;
