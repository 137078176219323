<script>
import { apiGetCheerNum } from "@/http";

import InternalPagesLayout from "@/layouts/InternalPagesLayout.vue";

import iconCoins from "@/assets/images/icon_coins.png";

export default {
  components: {
    InternalPagesLayout,
  },
  data() {
    return {
      iconCoins,

      nums: {},
    };
  },
  methods: {
    refresh(done) {
      this.getNums()?.then(() => done());
    },

    getNums() {
      return apiGetCheerNum().then((res) => {
        this.$set(this, "nums", res.data);
      });
    },

    handleNavigateTo(path) {
      this.$router.push(`/choujiang/me/${path}`);
    },
  },
  mounted() {
    this.getNums();
  },
};
</script>

<template>
  <InternalPagesLayout :refresh="refresh">
    <div class="page">
      <!-- <div class="picture">
        <img :src="nums.img" />
      </div> -->
      <div class="nickname">{{ nums.username || '--' }}</div>

      <div class="panels">
        <div class="panel">
          <img class="icon" :src="iconCoins" />
          <span class="label">积分</span>
          <span class="value">{{ nums.sign_integrate ?? "--" }}</span>
        </div>
        <div class="panel">
          <img class="icon" :src="iconCoins" />
          <span class="label">竞猜币</span>
          <span class="value">{{ nums.integrate ?? "--" }}</span>
        </div>
      </div>

      <div class="card">
        <div class="title">操作记录</div>
        <div class="body">
          <div
            class="record-item"
            @click="handleNavigateTo('history/encourage')"
          >
            助威记录
          </div>
          <div class="record-item" @click="handleNavigateTo('history/bet')">
            竞猜记录
          </div>
          <div class="record-item" @click="handleNavigateTo('history/prize')">
            中奖记录
          </div>
          <div class="record-item" @click="handleNavigateTo('history/points')">
            积分记录
          </div>
        </div>
      </div>
    </div>
  </InternalPagesLayout>
</template>

<style scoped lang="less">
.page {
  padding: 0px 12px;
  padding-bottom: 60px;
  box-sizing: border-box;

  .picture {
    height: 60px;
    width: 60px;
    border-radius: 60px;
    background-color: #f7f7f7;
    margin: auto;
    margin-top: 32px;
    margin-bottom: 12px;

    img {
      height: 60px;
      width: 60px;
      border-radius: 60px;
      object-fit: cover;
      display: block;
    }
  }

  .nickname {
    font-family: Tencent;
    font-size: 16px;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .panels {
    .panel {
      display: flex;
      align-items: center;
      height: 44px;
      border-radius: 6px;
      background: #ffffff;
      box-shadow: 0 1px 5px 0 #ababab33;
      padding: 0px 12px;
      box-sizing: border-box;
      margin-bottom: 6px;

      .icon {
        width: 16px;
        margin-right: 12px;
      }

      .value {
        margin-left: auto;
        color: #ff6a48;
      }
    }
  }

  .card {
    margin-top: 32px;

    .title {
      font-size: 12px;
      text-align: left;
      color: #9b9b9b;
      padding-left: 12px;
      box-sizing: border-box;
    }

    .body {
      margin-top: 8px;
      border-radius: 6px;
      background: rgb(255, 255, 255);
      box-shadow: 0 1px 5px 0 rgba(171, 171, 171, 0.2);

      .record-item {
        height: 44px;
        display: flex;
        align-items: center;
        padding: 0px 12px;
        box-sizing: border-box;
        border-bottom: solid 1px #f8f9fc;
        font-size: 14px;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
