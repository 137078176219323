<template>
  <div class="box">
    <Act />
    <div>
      <!-- 白名单可见 -->
      <router-link
        v-if="$store.state.userInfo.status == 2"
        to="/choujiang/create"
        class="toCreate"
      >
        <p>我的题目</p>
        <p><van-icon name="arrow" class="icon" /></p>
      </router-link>

      <p class="myBet">我的投注</p>
      <!-- 正文内容 -->
      <div class="section" v-if="dataList.length > 0">
        <!-- 卡片 -->
        <div class="card" v-for="item in dataList" :key="item.id">
          <div v-if="item.status != 3">
            <div class="main_section" @click="toBet(item.subject.id)">
              <div>
                <p
                  style="
                    text-align: left;
                    line-height: 20px;
                    font-size: 14px;
                    white-space: normal;
                  "
                >
                  题目:{{ item.subject.name }}
                </p>
              </div>
              <div style="margin-bottom: 10px" v-if="item.subject.is_send == 2">
                <p
                  style="
                    text-align: left;
                    line-height: 20px;
                    color: #999;
                    font-size: 12px;
                    white-space: normal;
                  "
                >
                  {{ item.subject.send_at.substring(5) }}
                  {{ item.created_at }} [队伍:{{ item.subject_option.content }}
                  {{
                    item.subject_option.content ==
                    item.subject.win_subject_option.content
                      ? "胜"
                      : "负"
                  }}] 投 {{ item.sum_integrate }}积分，奖池共{{
                    item.subject.subject_option[0].integrate +
                    item.subject.subject_option[1].integrate
                  }}积分
                </p>
              </div>
              <div style="margin-bottom: 10px" v-else>
                <p style="text-align: left; line-height: 20px; color: #999">
                  {{ item.created_at }} [队伍:{{ item.subject_option.content }}]
                  投 {{ item.sum_integrate }}积分，奖池共{{
                    item.subject.subject_option[0].integrate +
                    item.subject.subject_option[1].integrate
                  }}积分
                </p>
              </div>
              <div
                class="main_dis"
                v-for="(it, ind) in item.subject.subject_option"
                :key="it.id"
              >
                <p
                  class="max_width"
                  :style="it.content.length > 12 ? 'font-size:12px' : ''"
                >
                  {{ ind + 1 }}、{{ it.content }}
                </p>
                <p class="odds">赔率： {{ it.the_odds }}</p>
              </div>
              <div
                class="lottery"
                v-if="
                  item.subject.win_subject_option == null &&
                  item.subject.status != 3
                "
              >
                <p>答案：待公布</p>
                <p>待开奖</p>
              </div>
              <div class="lottery" v-else-if="item.subject.status == 3">
                <p>该题目已撤销，积分已返回</p>
                <p style="color: #e96346">+{{ item.sum_integrate }}</p>
              </div>
              <div class="lottery" v-else>
                <p>答案：{{ item.subject.win_subject_option.content }}胜利</p>
                <p
                  :style="
                    item.sum_profit != 0 ? 'color: #E96346' : 'color: #0DBC79'
                  "
                >
                  {{
                    item.sum_profit != 0
                      ? "+" + item.sum_profit
                      : "-" + item.sum_integrate
                  }}
                </p>
              </div>
              <button class="bet" v-if="!item.state">
                前往查看
                <!-- <van-icon name="arrow" class="icon" /> -->
              </button>
            </div>
          </div>
        </div>
      </div>
      <van-empty
        :image="require('@/assets/images/empty.png')"
        style="margin-top: 100px"
        v-else
        description="暂无数据"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Icon } from "vant";
import { Empty } from "vant";
import Act from "../outLogin/index.vue";

Vue.use(Empty);
Vue.use(Icon);
export default {
  components: {
    Act,
  },
  data() {
    return {
      dataList: false,
    };
  },
  created() {
    this.getUserLog();
    this.getUser();
  },
  methods: {
    //更新用户数据
    async getUser() {
      if (localStorage.auth_token) {
        const res = await this.$api.UserInfo.getUser();
        if (res.errorCode == 1000) {
          this.$store.commit("changeLoginState", res.data);
        }
      }
    },
    toBet(id) {
      this.$router.push({
        path: "/choujiang/bet",
        query: {
          id: id,
        },
      });
    },
    async getUserLog() {
      const res = await this.$api.Record.getUserLog();
      if (res.errorCode == 1000) {
        this.dataList = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  flex-direction: column;
  align-items: center;
  color: #000;
  font-size: 16px;
  // background: #f4f9ff;
  box-sizing: border-box;
  margin-bottom: 70px;

  .myBet {
    width: 96%;
    margin: auto;
    text-align: left;
    padding-left: 15px;
    box-sizing: border-box;
    margin-top: 20px;
  }
  // height: calc(100vh-40px-60px);
  // white-space: nowrap;
  // overflow-y: scroll;
  // overflow-x: hidden;
}
.toCreate {
  width: 96%;
  margin: auto;
  height: 40px;
  line-height: 40px;
  // border: 1px solid #ddd;
  background: white;
  display: flex;
  color: black;
  padding-left: 35px;
  padding-right: 20px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px;
  margin-top: 15px;
  margin-left: 2%;
  border: solid 1px #e96346;
  .icon {
    vertical-align: middle;
    font-size: 18px;
    font-weight: bold;
  }
}
.section {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: 5px;
}
.card {
  box-shadow: 0 0 5px 0 #ddd;
  padding-top: 5px;
  background: white;
  box-sizing: border-box;
  padding-left: 10px;
  margin-bottom: 20px;
  border-radius: 3px;
}
.main {
  width: 100%;
  padding: 0 9px;
  box-sizing: border-box;
  margin-top: 10px;
}
.main_section {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  p {
    margin: 0;
    margin-bottom: 10px;
  }
  .main_dis {
    display: flex;
    justify-content: space-between;
    min-height: 34px;
    width: 100%;
    background: #f8f9fc;
    margin-bottom: 5px;
    align-items: center;
    padding: 0;
    padding: 10px 10px 0px 10px;
    box-sizing: border-box;
    .max_width {
      width: 75%;
      text-align: left;
      word-break: break-all;
    }
    .odds {
      color: #999;
      min-width: 85px;
      // text-align: right;
    }
  }
  .lottery {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #999;
    font-size: 14px;
    margin-top: 20px;
    p {
      max-width: 80%;
      text-align: left;
    }
  }
  .bet {
    margin: 0;
    height: 40px;
    background: #e96346;
    border: none;
    width: 90%;
    color: white;
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 16px;

    .icon {
      vertical-align: text-top;
      font-size: 16px;
    }
  }
}
</style>
