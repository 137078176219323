<script>
import imageEmpty from "@/assets/images/empty1.png";

export default {
  props: {
    message: {
      type: String,
      default: () => {
        return "暂无数据";
      },
    },
    image: {
      type: String,
      default: () => {
        return imageEmpty;
      },
    },
  },
};
</script>

<template>
  <div class="empty">
    <img :src="image" />
    <div class="message">
      {{ message }}
    </div>
  </div>
</template>

<style scoped lang="less">
.empty {
  padding-top: 40px;
  img {
    width: 60%;
    display: block;
    margin: auto;
  }

  .message {
    font-size: 14px;
    text-align: center;
    color: #9b9b9b;
    margin-top: 32px;
  }
}
</style>
