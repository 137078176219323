import Subject from './modules/subject';
import Rank from './modules/rank';
import Record from './modules/record';
import UserInfo from './modules/userInfo';
import lottery from './modules/lottery';
import Bank from './modules/bank';

const api = {
  Subject,
  Rank,
  Record,
  UserInfo,
  lottery,
  Bank,
};

export default api;
