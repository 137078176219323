import axios from '../../utils/axios';

// 竞猜题目列表
const subject = {
  getSubjectList(params) {
    // params = { date: "2021-12-03" };
    // params.date = params.date+' '+'23:59:59'
    // console.log(params);
    return axios('/ann_api/h5/getSubjectList', { params });
  },
  // 竞猜详情列表
  getSubjectOptionList(params) {
    return axios('/ann_api/h5/getSubjectOptionList', { params });
  },
  // 投注操作
  createBet(data) {
    return axios.post('/ann_api/h5/userSubjectLog/create', data);
  },
  // 用户选项投注记录
  getUserSubjectLog(params) {
    return axios('/ann_api/h5/getUserSubjectLog', { params });
  },
  // 选项投注记录
  getSubjectLog(params) {
    return axios('/ann_api/h5/getSubjectLog', { params });
  },
  // 撤销投注
  cancelBet(data) {
    return axios.post('/ann_api/h5/userSubjectLog/cancel', data);
  },
  // 竞猜列表 分类
  mylist(params) {
    return axios('/ann_api/h5/subject/mylist', { params });
  },
};
export default subject;
