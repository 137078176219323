<script>
import InternalPagesLayout from "@/layouts/InternalPagesLayout.vue";
import Empty from "@/components/Empty.vue";

import iconSpeaker from "@/assets/images/icon_speaker.png";
import imageEmpty from "@/assets/images/empty2.png";

import { mixinableInfiniteLoad, mixinablePagination } from "@/mixins";

export default {
  mixins: [mixinableInfiniteLoad, mixinablePagination],
  components: {
    InternalPagesLayout,
    Empty,
  },
  data() {
    return {
      iconSpeaker,
      imageEmpty,

      // mixin
      infiniteLoadApi: "/ann_api/luck/userPrize",
      infiniteLoadTriggerDistance: 126,

      // mixin
      pagination: {
        size: 20,
      },
    };
  },
  methods: {
    refresh(done) {
      this.pagination.page = 0;
      this.pagination.total = 0;
      this.pagination.items = [];

      this.$nextTick(() => {
        this.infiniteLoadReload()?.then(() => done());
      });
    },

    // mixin
    infiniteLoadProcess(res) {
      const items = res.data.data;
      const total = res.data.total;
      const page = res.data.current_page;

      this.pagination.items.push(...items);
      this.pagination.total = total;
      this.pagination.page = page;
    },

    // mixin
    paginationChangeHandler() {
      console.log(this.pagination.page, this.pagination.size);
    },
  },
  computed: {
    // mixin
    infiniteLoadEnable() {
      if (this.active < 0) {
        return false;
      }

      if (
        this.pagination.items.length >= this.pagination.total &&
        this.pagination.items.length !== 0
      ) {
        return false;
      }

      return true;
    },

    // mixin
    infiniteLoadParams() {
      return {
        game_id: this.activeGame,
        page: this.pagination.page + 1,
        page_er: this.pagination.size,
      };
    },
  },
  mounted() {
    this.infiniteLoadReload();
  },
};
</script>

<template>
  <InternalPagesLayout title="中奖记录" :refresh="refresh">
    <div class="page">
      <div class="alert">
        <img :src="iconSpeaker" alt="" class="icon" />
        <div class="message">
          温馨提示：助威活动期间获得的实物礼品或其他奖励将通过企业微信拉群发放，请留意后续秘书通知。
        </div>
      </div>

      <div class="items">
        <div class="item" v-for="item in pagination.items" :key="item.id">
          <div class="item_info">
            <div class="item_name">{{ item.prize_name || "--" }}</div>
            <div class="item_create_time">{{ item.extract_at }}</div>
          </div>
          <div class="item_action">×1</div>
        </div>
      </div>

      <div class="loading" v-if="infiniteLoadPending">正在加载</div>

      <div
        class="nodata"
        v-if="!infiniteLoadPending && pagination.items.length === 0"
      >
        <Empty message="还没有参与过助威哦，快去助威吧！" :image="imageEmpty" />
      </div>
    </div>
  </InternalPagesLayout>
</template>

<style scoped lang="less">
.page {
  .alert {
    background-color: #fff7e1;
    padding: 14px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    margin: 12px;

    .icon {
      width: 20px;
      margin-right: 8px;
      margin-top: 4px;
    }

    .message {
      color: #9b9b9b;
      text-align: left;
      font-size: 12px;
    }
  }

  .items {
    box-sizing: border-box;
    .item {
      background: #ffffff;
      box-shadow: 0 1px 5px 0 #ababab33;
      padding: 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      .item_info {
        text-align: left;

        .item_create_time {
          font-size: 12px;
          color: #9ba2a5;
          margin-top: 4px;
        }
      }

      .item_action {
        margin-left: auto;
      }
    }
  }

  .loading {
    color: #9ba2a5;
    line-height: 300px;
  }

  .nodata {
    margin-top: 40px;
  }
}
</style>
