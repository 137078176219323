<script>
import InternalPagesLayout from "@/layouts/InternalPagesLayout.vue";
import Record from "@/components/record/index.vue";

export default {
  components: {
    InternalPagesLayout,
    Record,
  },
  data() {
    return {};
  },
  methods: {
    refresh() {
      this.$router.go(0);
    },
  },
};
</script>

<template>
  <InternalPagesLayout :refresh="refresh" title="竞猜记录">
    <Record />
  </InternalPagesLayout>
</template>

<style scoped lang="less"></style>
