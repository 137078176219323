<script>
import { Loading, PullRefresh, ActionSheet, Toast } from "vant";

import iconBet from "@/assets/images/tab-icon/bet.png";
import iconBetActive from "@/assets/images/tab-icon/bet_active.png";
import iconMe from "@/assets/images/tab-icon/me.png";
import iconMeActive from "@/assets/images/tab-icon/me_active.png";
import iconRanking from "@/assets/images/tab-icon/ranking.png";
import iconRankingActive from "@/assets/images/tab-icon/ranking_active.png";
import iconEncourage from "@/assets/images/tab-icon/encourage.png";
import iconEncourageActive from "@/assets/images/tab-icon/encourage_active.png";

import CommonLayout from "./CommonLayout.vue";

const ICONS = {
  "choujiang/guessResult": {
    default: iconBet,
    active: iconBetActive,
  },
  "choujiang/me": {
    default: iconMe,
    active: iconMeActive,
  },
  "choujiang/rankList": {
    default: iconRanking,
    active: iconRankingActive,
  },
  "choujiang/encourage": {
    default: iconEncourage,
    active: iconEncourageActive,
  },
};

const TABS = [
  {
    label: "竞猜",
    title: "竞猜专区",
    name: "choujiang/guessResult",
  },
  {
    label: "助威",
    title: "助威专区",
    name: "choujiang/encourage",
  },
  {
    label: "排名",
    title: "排行榜",
    name: "choujiang/rankList",
  },
  {
    label: "我的",
    title: "我的",
    name: "choujiang/me",
  },
];

export default {
  components: {
    CommonLayout,
    PullRefresh,
  },
  props: {
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
    refresh: {
      type: Function,
    },
    tabbar: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  data() {
    return {
      icons: ICONS,
      tabs: TABS,
      refreshing: false,
    };
  },
  methods: {
    handleToggleTab(name) {
      this.$router.push(`/${name}`);
    },
    handleRefresh() {
      this.refresh?.(() => {
        this.refreshing = false;
      });
    },
  },
  computed: {
    activeTab() {
      for (const tab of TABS) {
        if (this.$route.path.startsWith(`/${tab.name}`)) {
          return tab.name;
        }
      }
      return "bet";
    },
    activeTabTitle() {
      if (this.title) {
        return this.title;
      }

      return TABS.find((tab) => tab.name === this.activeTab)?.title;
    },
  },
};
</script>

<template>
  <CommonLayout :title="activeTabTitle">
    <div class="layout">
      <PullRefresh
        v-model="refreshing"
        :disabled="!refresh"
        success-text="刷新成功"
        @refresh="handleRefresh"
      >
        <slot />
      </PullRefresh>

      <div class="tab_bar" v-if="tabbar">
        <div class="tab_bar_inner">
          <div
            v-for="item in tabs"
            :key="item.name"
            :class="['tab_item', activeTab === item.name && 'tab_item__active']"
            @click="handleToggleTab(item.name)"
          >
            <div class="tab_icon">
              <img
                class="tab_icon_image"
                :src="
                  activeTab === item.name
                    ? icons[item.name].active
                    : icons[item.name].default
                "
              />
            </div>
            <div class="tab_label">
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
      <div class="tab_bar_placeholder" v-if="tabbar" />
    </div>
  </CommonLayout>
</template>

<style scoped lang="less">
@import "@/assets/font/index.less";

.layout {
  .tab_bar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background-color: #fff;
    padding-bottom: calc(10px + constant(safe-area-inset-bottom));
    padding-bottom: calc(10px + env(safe-area-inset-bottom));
    box-shadow: 0 0 6px 0 #0000001a;

    .tab_bar_inner {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 70px;

      .tab_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .tab_icon {
          height: 22px;
          width: 22px;

          .tab_icon_image {
            height: 100%;
            width: 100%;
          }
        }

        .tab_label {
          font-size: 12px;
          color: #838c8f;
          font-family: Tencent;
          font-weight: 400;
          margin-top: 6px;
        }

        &__active {
          .tab_label {
            color: #f0745b;
          }
        }
      }
    }
  }

  /deep/ .van-pull-refresh {
    overflow: visible;
  }

  .tab_bar_placeholder {
    height: calc(70px + 10px + constant(safe-area-inset-bottom));
    height: calc(70px + 10px + env(safe-area-inset-bottom));
  }
}
</style>
