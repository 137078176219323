import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Router from 'vue-router';
import store from './store';
// import axios from 'axios'
import api from './http/api';
import scroll from 'vue-seamless-scroll';

Vue.use(scroll);
// Vue.use(vant);

Vue.prototype.$api = api;
Vue.config.productionTip = false;
// localStorage.removeItem("auth_token")

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
function initRem() {}

window.addEventListener('resize', () => {
  initRem();
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
