<script>
import { apiGetGames } from "@/http";

export default {
  data() {
    return {
      active: -1,
      games: [],
    };
  },
  methods: {
    getGames() {
      apiGetGames().then((res) => {
        this.games = res.data;
        this.active = res.data[0].id;
        this.emitChange();
      });
    },

    emitChange() {
      this.$nextTick(() => {
        this.$emit("change", {
          id: this.active,
          picture: this.activeGamePicture,
        });
      });
    },

    handleActive(id) {
      this.active = id;
      this.emitChange();
    },
  },
  computed: {
    activeGame() {
      return this.games.find((item) => item.id === this.active);
    },
    activeGamePicture() {
      const prefix =
        process.env.NODE_ENV === "development" ? "/ann_assets" : "";
      return prefix + this.activeGame?.img || "/";
    },
  },

  beforeMount() {
    this.getGames();
  },
};
</script>

<template>
  <div class="tab">
    <div
      v-for="item in games"
      :class="['tab_item', active === item.id && 'tab_item__active']"
      :key="item.id"
      @click="handleActive(item.id)"
    >
      {{ item.name }}
      <div class="line" />
    </div>
  </div>
</template>

<style scoped lang="less">
.tab {
  padding: 0px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;

  .tab_item {
    font-size: 16px;
    font-family: Tencent;
    color: rgba(171, 171, 171, 1);

    .line {
      width: 30px;
      height: 2px;
      border-radius: 2px 2px 2px 2px;
      background: rgb(240, 116, 91);
      margin: auto;
      margin-top: 4px;
      opacity: 0;
    }

    &__active {
      color: rgba(35, 35, 35, 1);

      .line {
        opacity: 1;
      }
    }
  }
}
</style>
