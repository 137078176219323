import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home';

import { Notify } from 'vant';
Vue.use(VueRouter);

import Me from "@/views/me";
import HistoryBet from "@/views/me/history/bet";
import HistoryEncourage from "@/views/me/history/encourage";
import HistoryPoints from "@/views/me/history/points";
import HistoryPrize from "@/views/me/history/prize";


const routes = [
  {
    path: '/',
    redirect: '/choujiang/landing',
  },
  {
    path: '/choujiang',
    redirect: '/choujiang/landing',
  },
  {
    path: '/choujiang',
    name: 'Home',
    component: Home,
    children: [
      {
        path: 'guessResult',
        name: 'guessResult', // 命名路由
        component: () => import('@/components/guessResult/index.vue'),
        meta: {
          title: '猜胜负',
        },
      },
      {
        path: 'rankList',
        name: 'rankList', // 命名路由
        component: () => import('@/views/ranking/index.vue'),
        meta: {
          title: '排行榜',
        },
      },
      {
        path: 'record',
        name: 'record', // 命名路由
        component: () => import('@/components/record/index.vue'),
        meta: {
          title: '记录',
        },
      },
      {
        path: '/choujiang/bet',
        name: 'bet', // 命名路由
        component: () => import('@/views/bet/index.vue'),
        meta: {
          title: '投注',
        },
      },
      {
        path: '/choujiang/create',
        name: 'create', // 命名路由
        component: () => import('@/views/create/index.vue'),
        meta: {
          title: '新建',
        },
      },
      {
        path: '/choujiang/bank',
        name: 'bank',
        component: () => import('@/views/bank/index.vue'),
        meta: {
          title: '银行',
        },
      },
      {
        path: '/choujiang/sealDisk',
        name: 'sealDisk', // 命名路由
        component: () => import('@/views/sealDisk/index.vue'),
        meta: {
          title: '封盘',
        },
      },
      {
        path: '/choujiang/rent/404',
        name: 'notFound', // 命名路由
        component: () => import('@/views/notFound/index.vue'),
        meta: {
          title: '页面哦豁了（；´д｀）ゞ',
        },
      },
      {
        path: '/choujiang/lottery',
        name: 'lottery', // 命名路由
        component: () => import('@/views/lottery/home.vue'),
        meta: {
          title: '',
        },
        children: [
          {
            path: '/choujiang/lottery/index',
            name: 'lotteryIndex', // 命名路由
            component: () => import('@/views/lottery/index.vue'),
            meta: {
              title: '竞猜押宝喝彩礼包抽奖',
            },
          },
          {
            path: '/choujiang/lottery/list',
            name: 'lotteryList', // 命名路由
            component: () => import('@/views/lottery/list.vue'),
            meta: {
              title: '竞猜押宝喝彩礼包抽奖',
            },
          },
        ],
      },
      {
        path: '/choujiang/login',
        name: 'login', // 命名路由
        component: () => import('@/views/login/index.vue'),
        meta: {
          title: '登录',
        },
      },
      {
        path: '/choujiang/encourage',
        name: 'login', // 命名路由
        component: () => import('@/views/encourage/index.vue'),
        meta: {
          title: '登录',
        },
      },
      {
        path: "/choujiang/me",
        component: Me,
      },
      {
        path: "/choujiang/me/history/bet",
        component: HistoryBet,
      },
      {
        path: "/choujiang/me/history/encourage",
        component: HistoryEncourage,
      },
      {
        path: "/choujiang/me/history/points",
        component: HistoryPoints,
      },
      {
        path: "/choujiang/me/history/prize",
        component: HistoryPrize,
      },
    ],
  },
  {
    path: '/choujiang/landing',
    component: () => import('@/views/landing/index.vue')
  },
  {
    path: '/choujiang/raffle',
    component: () => import('@/views/raffle/index.vue')
  }
];

const router = new VueRouter({
  mode: 'hash', // hash  history
  // base: process.env.BASE_URL,
  routes,
});
// 全局前置导航守卫
router.beforeEach((to, from, next) => {
  const input = document.getElementsByTagName('input');
  console.log('输入框集合');
  console.log(input);
  for (const item of input) {
    item.blur();
  }
  if (to.path !== '/choujiang/login') {
    if (localStorage.getItem('auth_token')) {
      next();
    } else {
      Notify({ type: 'warning', message: '请先登录' });
      next({
        path: '/choujiang/login',
        query: { url: router.history._startLocation },
      });
    }
  } else {
    next();
  }
});

export default router;
