import axios from "@/utils/axios";

export const mixinableInfiniteLoad = {
  data() {
    return {
      infiniteLoadDefers: [],
      infiniteLoadApi: "",
      infiniteLoadPending: false,
      infiniteLoadTriggerDistance: 0,
    };
  },
  beforeMount() {
    this.__infiniteLoadInit();
  },
  beforeDestroy() {
    this.infiniteLoadDefers.map((defer) => defer());
  },
  methods: {
    /**
     * Mixin 私有方法
     */
    __infiniteLoadHandler(force = false) {
      this.__infiniteLoadPreHandler();

      console.log('000')

      const {
        infiniteLoadApi: api,
        infiniteLoadPending: pending,
        infiniteLoadEnable: enable,
        infiniteLoadParams: params,
        infiniteLoadTriggerDistance: triggerDistance,
      } = this;

      if (!enable || pending) {
        return Promise.resolve();
      }

      const scrollHeight = window.innerHeight + window.scrollY;
      const offsetHeight = document.body.offsetHeight - triggerDistance;

      if (scrollHeight >= offsetHeight || force) {
        this.infiniteLoadPending = true;
         return axios(api, { params })
          .then((res) => {
            this.infiniteLoadPending = false;
            this.infiniteLoadProcess(res);
          })
          .catch((err) => {
            this.infiniteLoadPending = false;
            this.infiniteLoadErrorProcess(err);
          });
      }

      return Promise.resolve();
    },

    __infiniteLoadPreHandler() {
      if (typeof this.infiniteLoadApi !== "string" || !this.infiniteLoadApi) {
        throw new Error(
          "The value of this.infiniteLoadApi is invalid; it must be a non-empty string."
        );
      }

      if (typeof this.infiniteLoadPending !== "boolean") {
        throw new Error(
          "The value of this.infiniteLoadPending is invalid; it must be a boolean."
        );
      }

      if (typeof this.infiniteLoadTriggerDistance !== "number") {
        throw new Error(
          "The value of this.infiniteLoadTriggerDistance is invalid; it must be a number."
        );
      }
    },

    __infiniteLoadInit() {
      const self = this;

      const handleScroll = () => {
        self.__infiniteLoadHandler();
      };

      self.infiniteLoadDefers.push(() => {
        document.removeEventListener("scroll", handleScroll);
      });

      document.addEventListener("scroll", handleScroll);
    },

    /**
     * 需要使用者实现的数据处理逻辑
     */
    infiniteLoadProcess() {
      throw new Error(
        "Please implement the 'infiniteLoadProcess' method yourself."
      );
    },

    /**
     * 使用者可实现的错误处理逻辑
     * 默认打印并忽略
     */
    infiniteLoadErrorProcess(err) {
      console.error(err);
    },

    infiniteLoadReload() {
      return this.__infiniteLoadHandler(true);
    },
  },
  computed: {
    infiniteLoadEnable() {
      return true;
    },

    infiniteLoadParams() {
      return {};
    },
  },
};
