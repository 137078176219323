import axios from '../../utils/axios';

// 竞猜题目列表
const rank = {
  total(params) {
    return axios('/ann_api/h5/ranking/total', { params });
  },
  rise(params) {
    return axios('/ann_api/h5/ranking/rise', { params });
  },
  decline(params) {
    return axios('/ann_api/h5/ranking/decline', { params });
  },
};
export default rank;
