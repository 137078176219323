<script>
import imageBackLight from "@/assets/images/back_light.png";
import imageBackDark from "@/assets/images/back_dark.png";

export default {
  props: {
    title: {
      type: String,
      default: () => {
        return "共创杯·竞猜互动";
      },
    },
    // light || dark
    color: {
      type: String,
      default: () => {
        return "dark";
      },
    },
    transparentHeader: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    headerPlaceholder: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    titleColor() {
      return this.color === "dark" ? "#000" : "#FFF";
    },
    backIcon() {
      return this.color === "dark" ? imageBackDark : imageBackLight;
    },
  },
  methods: {
    handleBack() {
      this.$router.back();
    },
  },
};
</script>

<template>
  <div class="layout">
    <div :class="['header', transparentHeader && 'header__transparent']">
      <div class="back" @click="handleBack">
        <img :src="backIcon" class="back_icon" />
      </div>
      <div class="title" :style="`color: ${titleColor}`">
        <span style="vertical-align: middle">
          {{ title }}
        </span>
      </div>
    </div>
    <div v-if="headerPlaceholder" class="header_placeholder" />
    <slot />
  </div>
</template>

<style scoped lang="less">
@import "@/assets/font/index.less";
@import "@/styles/design.less";

@header-height: 32px + 28px;

.layout {
  position: relative;
  width: 100%;

  .header {
    position: fixed;
    top: 0;
    left: 0;
    height: @header-height;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    background-color: #ffffff;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 0 10px 0 #d5d5d5cc;
    box-sizing: border-box;

    .back {
      margin-right: 10px;
      margin-top: 5px;

      .back_icon {
        height: 16px;
      }
    }

    .title {
      font-size: 16px;
      font-family: Tencent;
      font-weight: 400;
    }

    &__transparent {
      background-color: transparent;
      box-shadow: none;
    }
  }

  .header_placeholder {
    height: @header-height;
  }
}
</style>
