import Vue from 'vue';
import Vuex from 'vuex';
import api from '../http/api';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {
      additional: {},
    },
  },
  getters: {
    getUserInfo: state => state.userInfo,
  },
  mutations: {
    changeLoginState(state, data) {
      state.userInfo = data;
    },
  },
  actions: {
    // 获取用户信息
    async fetchUserInfo({ state }) {
      const res = await api.UserInfo.getUser({});
      state.userInfo = res.data;
    },

  },
  modules: {
  },
});
