<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import VConsole from "vconsole";
export default {
  methods: {
    async getUser() {
      if (localStorage.auth_token) {
        const res = await this.$api.UserInfo.getUser();
        if (res.errorCode == 1000) {
          this.$store.commit("changeLoginState", res.data);
        }
      }
    },
  },
  created() {
    if (
      location.href.indexOf("igame-mo-test") > -1 ||
      this.$route.query?.vconsole === "true"
    ) {
      new VConsole();
    }
    this.getUser();
  },
};
</script>
<style lang="less">
@import "./assets/font/font.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 16px;
  background: #f8f9fc;
  color: #000000;
}
html {
  background: #f8f9fc;
}
body {
  background: #f8f9fc;
  padding: 0;
  margin: 0;
}
</style>
