import axios from '../../utils/axios';

const record = {
  // 创建题目
  create(data) {
    return axios.post('/ann_api/h5/subject/create', data);
  },
  // 获取用户投注记录
  getUserLog(params) {
    return axios('/ann_api/h5/getUserLog', { params });
  },
  // 获取用户发布的题目
  mylist(params) {
    return axios('/ann_api/h5/subject/mylist', { params });
  },
  // 手动封盘
  disable(data) {
    return axios.post('/ann_api/h5/subject/disable', data);
  },
  // 确认获胜队伍
  confirm(data) {
    return axios.post('/ann_api/h5/subject/confirm', data);
  },
  // 撤销题目
  revoke(data) {
    return axios.post('/ann_api/h5/subject/revoke', data);
  },
  //
  getSubjectWinLog(params) {
    return axios('/ann_api/h5/getSubjectWinLog', { params });
  },
};
export default record;
