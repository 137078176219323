<template>
  <div class="surplus">
    <div style="display: flex; align-items: center">
      <p
        class="div"
        :style="`background-image:url(${$store.state.userInfo.portrait})`"
        @click="Show()"
      >
        <img width="24px" src="@/assets/images/userHead.png" alt="" />
      </p>
      <p style="margin-left: 10px">{{ $store.state.userInfo.name }}</p>
    </div>
    <!--    <img-->
    <!--      width="20px"-->
    <!--      height="20px"-->
    <!--      style="margin-left: auto; margin-right: 10px"-->
    <!--      src="@/assets/images/bank.png"-->
    <!--      alt=""-->
    <!--      v-if="$route.path.startsWith('/choujiang/be') || $route.path.startsWith('/choujiang/record')"-->
    <!--      @click="$router.push('/choujiang/bank')"-->
    <!--    />-->
    <span style="color: #e96346"
      >竞猜币剩余：
      {{
        $store.state.userInfo ? $store.state.userInfo.additional.integrate : ""
      }}</span
    >

    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
  </div>
</template>

<script>
import Vue from "vue";
import { ActionSheet } from "vant";

Vue.use(ActionSheet);
export default {
  data() {
    return {
      show: false,
      actions: [{ name: "退出登录" }],
    };
  },
  methods: {
    //清空cookies
    clearAllCookie() {
      var date = new Date();
      date.setTime(date.getTime() - 10000);
      var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
      console.log("需要删除的cookie名字：" + keys);
      if (keys) {
        undefined;
        for (var i = keys.length; i--; )
          document.cookie =
            keys[i] + "=0; expire=" + date.toGMTString() + "; path=/";
      }
    },
    clearCookies() {
      var temp = document.cookie.split(";");
      var name;
      for (var i = 0; ; i++) {
        if (!temp[i]) break;
        name = temp[i].split("=")[0];
        var exp = new Date();
        exp.setTime(exp.getTime() - 1);
        document.cookie = name + "= ''" + "; expires=" + exp.toGMTString();
      }
    },
    //退出登录
    onSelect() {
      localStorage.clear();
      sessionStorage.clear();
      this.clearCookies();
      this.clearAllCookie();
      this.$router.push("/choujiang/login");
    },
    Show() {
      this.show = true;
    },
  },
};
</script>

<style lang="less" scoped>
.surplus {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid rgb(235, 235, 235);
  margin: 0;
  align-items: center;
  background: white;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  .div {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #ddd;
    background-repeat: no-repeat;
    background-size: cover;
    img {
      margin-right: 9px;
    }
  }
  span {
    color: #e96346;
  }
}
</style>
