import axios from '../../utils/axios';

// 抽奖
const lottery = {
  // 获取奖品轮播
  getRewardsList(params) {
    return axios.get('/ann_api/luck/luckDraw/bannerList', { params });
  },
  // 获取抽奖奖品
  getRewards(params) {
    return axios.post('/ann_api/luck/luckDraw', params);
  },
  // 获取用户抽奖列表
  getUserRewardsList(params) {
    return axios.get('/ann_api/luck/luckDraw/log', { params });
  },
};
export default lottery;
