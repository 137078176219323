<template>
  <router-view></router-view>
</template>

<script>
import Vue from "vue";
import { Toast } from "vant";
import { Tabbar, TabbarItem } from "vant";
import { PullRefresh } from "vant";

Vue.use(PullRefresh);
Vue.use(Tabbar);
Vue.use(TabbarItem);

export default {
  name: "App",
  data() {
    return {
      active: 0,
      ifmb: true,
      isLoading: false, //刷新
      url: "",
      tabs: [
        {
          name: "guessResult",
          label: "猜胜负",
          icon: {
            active: require("@/assets/images/guessT.png"),
            inactive: require("@/assets/images/guessF.png"),
          },
          link: "/choujiang/guessResult",
        },
        {
          name: "rankList",
          label: "排行榜",
          icon: {
            active: require("@/assets/images/rankT.png"),
            inactive: require("@/assets/images/rankF.png"),
          },
          link: "/choujiang/rankList",
        },
        {
          name: "record",
          label: "记录",
          icon: {
            active: require("@/assets/images/recordT.png"),
            inactive: require("@/assets/images/recordF.png"),
          },
          link: "/choujiang/record",
        },
      ],
    };
  },
  methods: {
    //下拉刷新
    onRefresh() {
      if (this.$route.path != "/choujiang/guessResult") {
        this.$router.go(0);
        setTimeout(() => {
          Toast("刷新成功");
          this.isLoading = false;
        }, 1000);
      }
    },
  },
  updated() {
    this.url = this.$route.path;
    if (
      this.$route.path == "/choujiang/sealDisk" ||
      this.$route.path == "/choujiang/guessResult"
    ) {
      this.active = 0;
    } else if (this.$route.path == "/choujiang/record") {
      this.active = 2;
    } else {
      this.active = 1;
    }
  },
  created() {
    console.log(this.$route);
    this.url = this.$route.path;
    //这里是记录当前点击导航的位置 下次刷新当前页面时 会记录当前位置
    let router_path = this.$route.path;
    if (
      router_path == "/choujiang/record" ||
      router_path == "/choujiang/create"
    ) {
      this.active = 2;
    } else if (router_path.indexOf("/choujiang/rankList") != -1) {
      this.active = 1;
    } else {
      this.active = 0;
    }
    if (
      this.$route.name == "guessResult" ||
      this.$route.name == "record" ||
      this.$route.name == "rankAll" ||
      this.$route.name == "rankUp" ||
      this.$route.name == "rankDown"
    ) {
      this.ifmb = true;
    } else {
      this.ifmb = false;
    }
  },
  watch: {
    $route: {
      handler() {
        // console.log(this.$route.name);
        if (
          this.$route.name == "guessResult" ||
          this.$route.name == "record" ||
          this.$route.name == "rankAll" ||
          this.$route.name == "rankUp" ||
          this.$route.name == "rankDown"
        ) {
          this.ifmb = true;
        } else {
          this.ifmb = false;
        }
      },
    },
    deep: true,
  },
};
</script>
<style lang="less" scoped>
.fix {
  position: fixed !important;
  bottom: 0 !important;
}
header {
  height: 40px;
  width: 100%;
  line-height: 40px;
  background: #f8f8f8;
  text-align: center;
  font-size: 16px;
  position: fixed;
  z-index: 1000;
  top: 0;
}
img {
  height: 35px !important;
  width: 35px !important;
}
::v-deep {
  .van-hairline--top-bottom {
    padding: 5px 0;
  }
}
</style>
