import axios from "@/utils/axios";

/**
 * 登录
 */
export const apiLogin = (data) => {
  return axios.post("/ann_api/h5/login", data);
};

/**
 * 获取用户数据
 */
export const apiGetUser = (params) => {
  return axios("/ann_api/h5/me", { params });
};

/**
 * 设置密码
 */
export const apiSetPassword = (data) => {
  return axios.post("/ann_api/h5/setPassword", data);
};

/**
 * 密码重置申请
 */
export const apiResetPasswordApply = (data) => {
  return axios.post("/ann_api/h5/resetApply", data);
};

/**
 * 获取所有游戏
 */
export const apiGetGames = () => {
  return axios.get("/ann_api/h5/team/getGame");
};

/**
 * 获取威力值排行榜
 */
export const apiGetTeams = (params) => {
  return axios("/ann_api/h5/team/getTeam", { params });
};

/**
 * 助威
 */
export const apiCheerUp = (data) => {
  return axios.post("/ann_api/h5/cheer/cheerUp", data);
};

/**
 * 获取用户助威信息
 */
export const apiGetCheerNum = () => {
  return axios("/ann_api/h5/cheer/cheerNum");
};

/**
 * 获取是否可以抽奖
 */
export const apiGetLuckDay = () => {
  return axios("/ann_api/luck/luckDay");
};

/**
 * 抽奖
 */
export const apiLuckDraw = () => {
  return axios.post("/ann_api/luck/luckDraw");
};

/**
 * 获取战队助威记录
 */
export const apiGetTeamLog = (params) => {
  return axios("/ann_api/h5/cheer/teamLog", { params });
};

/**
 * 获取积分收入支出
 */
export const apiGetCheerSum = () => {
  return axios("/ann_api/h5/cheer/sum");
};
