import axios from '../../utils/axios';

const userInfo = {
  // 用户数据
  getUser(params) {
    return axios('/ann_api/h5/me', { params });
  },
  // 用户登录
  login(data) {
    return axios.post('/ann_api/h5/login', data);
  },

  setPassword(data) {
    return axios.post('/ann_api/h5/setPassword', data);
  },

  resetApply(data) {
    return axios.post('/ann_api/h5/resetApply', data);
  },
};
export default userInfo;
