<script>
import InternalPagesLayout from "@/layouts/InternalPagesLayout.vue";
import GamesTab from "@/components/GamesTab.vue";
import Empty from "@/components/Empty.vue";

import { mixinableInfiniteLoad, mixinablePagination } from "@/mixins";

export default {
  mixins: [mixinableInfiniteLoad, mixinablePagination],
  components: {
    InternalPagesLayout,
    GamesTab,
    Empty,
  },
  data() {
    return {
      activeGame: -1,
      activeGamePicture: "",

      // mixin
      infiniteLoadApi: "/ann_api/h5/cheer/teamLog",
      infiniteLoadTriggerDistance: 126,

      // mixin
      pagination: {
        size: 20,
      },
    };
  },
  methods: {
    refresh(done) {
      this.pagination.page = 0;
      this.pagination.total = 0;
      this.pagination.items = [];

      this.$nextTick(() => {
        this.infiniteLoadReload()?.then(() => done());
      });
    },

    handleActiveGameChange(value) {
      this.activeGame = value.id;
      this.activeGamePicture = value.picture;

      this.pagination.page = 0;
      this.pagination.total = 0;
      this.pagination.items = [];

      this.$nextTick(() => {
        this.infiniteLoadReload();
      });
    },

    // mixin
    infiniteLoadProcess(res) {
      const items = res.data.data;
      const total = res.data.total;
      const page = res.data.current_page;

      this.pagination.items.push(...items);
      this.pagination.total = total;
      this.pagination.page = page;
    },

    // mixin
    paginationChangeHandler() {
      console.log(this.pagination.page, this.pagination.size);
    },
  },
  computed: {
    // mixin
    infiniteLoadEnable() {
      if (this.active < 0) {
        return false;
      }

      if (
        this.pagination.items.length >= this.pagination.total &&
        this.pagination.items.length !== 0
      ) {
        return false;
      }

      return true;
    },

    // mixin
    infiniteLoadParams() {
      return {
        game_id: this.activeGame,
        page: this.pagination.page + 1,
        page_er: this.pagination.size,
      };
    },
  },
};
</script>

<template>
  <InternalPagesLayout title="助威记录" :refresh="refresh">
    <div class="page">
      <div class="tab">
        <GamesTab @change="handleActiveGameChange" />
      </div>

      <div class="items">
        <div class="item" v-for="item in pagination.items" :key="item.id">
          <img class="item_picture" :src="activeGamePicture" />
          <div class="item_info">
            <div class="item_name">{{ item.team_name?.name || "--" }}</div>
            <div class="item_create_time">{{ item.created_at }}</div>
          </div>
          <div class="item_action">助威成功</div>
        </div>
      </div>

      <div class="loading" v-if="infiniteLoadPending">正在加载</div>

      <div
        class="nodata"
        v-if="!infiniteLoadPending && pagination.items.length === 0"
      >
        <Empty message="还没有参与过助威哦～，快去助威吧！" />
      </div>
    </div>
  </InternalPagesLayout>
</template>

<style scoped lang="less">
.page {
  .tab {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .items {
    box-sizing: border-box;
    .item {
      background: #ffffff;
      box-shadow: 0 1px 5px 0 #ababab33;
      padding: 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      .item_picture {
        width: 45px;
        border-radius: 8px;
      }

      .item_info {
        margin-left: 12px;
        text-align: left;

        .item_create_time {
          font-size: 12px;
          color: #9ba2a5;
          margin-top: 8px;
        }
      }

      .item_action {
        margin-left: auto;
        color: #c1c1c1;
      }
    }
  }

  .loading {
    color: #9ba2a5;
    line-height: 300px;
  }

  .nodata {
    margin-top: 40px;
  }
}
</style>
