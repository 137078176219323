import axios from '../../utils/axios';

// 竞猜题目列表
const bank = {
  getBank(data) {
    return axios.post('/ann_api/h5/bank/getBank', data);
  },
  returnBank(data) {
    return axios.post('/ann_api/h5/bank/returnBank', data);
  },
  getBankLog() {
    return axios('/ann_api/h5/bank/getBankLog');
  },
  canBank() {
    return axios('/ann_api/h5/bank/canBank');
  },
};
export default bank;
