export const mixinablePagination = {
  data() {
    return {
      pagination: {
        page: 0,
        size: 20,
        total: 0,
        items: [],
      },
    };
  },
  methods: {
    paginationChangeHandler() {
      console.warn("'paginationChangeHandler' method not implemented");
    },

    handlePaginationSizeChange(size) {
      this.pagination.size = size;
    },

    handlePaginationPageChange(page) {
      this.pagination.page = page;
    },
  },
  watch: {
    pagination: {
      handler(newPagination, oldPagination) {
        if (
          newPagination.page !== oldPagination.page ||
          newPagination.size !== oldPagination.size
        ) {
          this.paginationChangeHandler(newPagination.page, newPagination.size);
        }
      },
      deep: true,
    },
  },
};
